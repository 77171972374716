/* index.css */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Светлая тема */
.light-theme {
  --background-color: #ffffff;  /* Общий фон */
  --text-color: #000000;        /* Общий текст */

  --app-main-bg: #f0f0f0;       /* Например, фон в "основных блоках" */
  --modal-bg: #fefefe;          /* Фон модального окна */
  --title-color: #222222;       /* Цвет заголовков и т.д. */
}

/* Тёмная тема */
.dark-theme {
  --background-color: #2d2d2d;
  --text-color: #ffffff;

  --app-main-bg: #1f1f1f;
  --modal-bg: #2f2f2f;
  --title-color: #ffffff;
}

/* Тот же глобальный стиль для body */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.text-icon-wrapper:hover .tooltip-content {
  display: block;
}