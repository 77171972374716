/* LoadingButton.css */

/* Основные стили для кнопки */
.loading-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 6px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    position: relative; /* Для позиционирования спиннера */
    padding: 8px 16px; /* Отступы вокруг текста */
}

/* Варианты размеров */
.loading-button.classic {
    font-size: 16px;
}

.loading-button.small {
    font-size: 14px;
    padding: 6px 12px;
}

/* Стиль при наведении */
.loading-button:hover:not(:disabled) {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

/* Стиль при нажатии */
.loading-button:active:not(:disabled) {
    transform: scale(0.95);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Отключённая кнопка */
.loading-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Текст остаётся невидимым при загрузке */
.loading-button-text {
    visibility: visible;
}

.loading-button-loading .loading-button-text {
    visibility: hidden; /* Прячем текст при загрузке */
}

/* Спиннер */
.loading-button-spinner {
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    position: absolute; /* Центрируем спиннер */
}

/* Анимация вращения */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}