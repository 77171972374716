/* Общие стили контейнера */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 20px;
    max-width: 600px;
    text-align: center;
}

/* Заголовки */
.label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #1f1f1f;
}

/* Стили для блока */
.block {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Текстовые области */
.textarea {
    width: 100%;
    max-width: 100%;
    min-height: 150px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.requester-selection {
    padding: 10px;
}

.textarea2 {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 200px;
    max-height: 900px;
    overflow-y: auto;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

/* Ввод названия */
.input {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
}

/* Стили кнопок */
.button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button.active {
    background-color: #007bff;
    color: white;
}

.button.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
}

.loading {
    font-size: 16px;
    color: #007bff;
}

/* Визуальные разделители */
.block + .block {
    margin-top: 30px; /* Отступ между блоками */
}

.block .button {
    margin-top: 20px; /* Единообразный отступ для кнопок в каждом блоке */
}